@import "./../../node_modules/bootstrap/dist/css/bootstrap.css";
body {
    background-color: #F5F5F5;
}

p{
    font-size: 1rem;
}

#home{
    padding-top: 90px;
}

#home{
    background-color: #F5F5F5;
    text-align: center;
}

.home{
    padding-top: 50px;
}

.greeting{
    padding-top: 25px;
    opacity: 0;
    transition-property: opacity;
    transition-duration: 4s;
}

.name{
    background:#343a40;
    color:#fff;
    width:200px;
    margin: auto;
    opacity: 0;
    transition-property: opacity;
    transition-duration: 7s;
}

.job{
    padding-top: 10px;
    opacity: 0;
    transition-property: opacity;
    transition-duration: 9s;
}

.alert{
    opacity: 1;
    transition-property: opacity;
    transition-duration: 10s;
}

.logo_motto {
    opacity: 0;
    transition-property: opacity;
    transition-duration: 10s;
}

nav{
    padding: 10px 10px!important;
}

.navbar-brand img{
    width: 50px;
}

li{
    list-style: none;
    padding-top: 10px;
    font-size: 1rem;
}

#about,#contact{
    padding-bottom: 50px;
}

.skill{
    padding-top: 20px;
}

.card-body{
    padding-top: 20px;
    text-align: left;
    min-height: 295px;
}

.about, .contact, .home{
    padding-top: 20px;
    min-height:300px;
}

.about_intro{
    padding-bottom: 25px;
    max-width:800px ;
    margin: auto;
}


.about{
    text-align: center;
}

#advitrust{
    width: 200px;
}

.page-footer{
    padding-top: 25px;
    text-align: center;
    background: #525d63;
}

.footer-copyright{
    margin-top:-10px;}

.form{
    text-align: center;
}

#send{
    width: 50px;
}

.contact{
    padding-bottom: 50px;
}

.contact_form{
    padding-top: 50px;
    padding-bottom: 20px;
    text-align: left;
    background: #FFFFFF;
}

#messenger{
    position: fixed;
    z-index: 2000;
    width: 40px;
    bottom: 25px;
    right: 25px;
}

#phone{
    height: 35px;
}

.contact_rapide_logo{
    width:25px;
    color: #000;
}

.list-unstyled{
    text-align: center;
    padding-top: 10px;
}

.list-unstyled li {
    display: inline;
    margin-top: 10px;
    color: #000!important;
}

#photo{
    width:250px;
    border-radius:500px; 
}

footer{
    text-align: center;
}

a:hover{
    text-decoration: none;
}

/*==================Portfolio======================*/
#portfolio{
    padding-top:90px;
}

.work{
    margin-bottom: 50px;
}

.work .card-body{
   min-height: 200px;
}


.view{
    margin-top:32%;
}

.view_project{
    position: absolute;
    top:0;
    bottom:0;
    left:0;
    right: 0;
    z-index: 1;
    text-align: center;
    opacity: 0;
    background: #C9C9C9;
    cursor: default!important;
}

.view_project:hover{
    opacity: 0.8;
    display: block;
    cursor: default!important;
}

.view:hover{
    cursor: pointer!important;
}

.work-item{
    width: 100%;
}
/*=================end_portfolio=================*/

@media only screen and (max-width:977px){
    #home_logo{
        width:75%;
    }

    .nav-item{
        padding: 10px!important;
    }
}


@media only screen and (max-width:755px){
   .list-unstyled{
    text-align: center;
    }
}


@media only screen and (max-width:632px){
    #home_logo{
        width:70%;
    }

    .contact_rapide{
        padding-top: 40px!important;
    }
}

@media only screen and (max-width:350px){
    #responsive{
        width: 90%;
    }
}
